<template>
  <invoices-list />
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoicesList from "@/modules/invoices/components/InvoicesList.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { InvoicesList },
})
export default class InvoicesSaved extends Mixins(PaginateProviderMixin) {}
</script>
